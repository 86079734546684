<template>
  <b-overlay :show="busyLoading" rounded="lg">
    <b-card title="Inventory List ">
      <b-modal
        id="promptDownload"
        ref="modal"
        v-model="promptDownload"
        title="Download"
        hide-footer
        @hidden="resetNoData"
      >
        <strong v-if="inventoryToDownload.length <= 0 && noData === false">Loading Data...</strong>
        <strong v-if="inventoryToDownload.length <= 0 && noData === true">No data found. Please try adjusting your search filters.</strong>
        <strong v-else>Loading data finished, total data is {{inventoryToDownload.length}}</strong>
        <div v-if="!noData">
          <strong> Progress {{ progressDownload }} %</strong>
        </div>
        <div style="display: table; margin: 0 auto;">
          <b-button :disabled="inventoryToDownload.length<totalRows || noData" variant="primary" style="margin-top:20px">
            <download-excel  :data="inventoryToDownload" :fields="filter" name="Inventory.xls" class="download-border">download</download-excel>  
          </b-button>
        </div>
      </b-modal> 
      <b-col cols="12">
        <b-row>
          <b-col cols="6">
            Start Date
            <b-form-datepicker :disabled="disabledButton"  @input="(value) => fireFiltering('startDate', value)" :max="endDate" :min="minmin" v-model="startDate"/>
          </b-col>
          <b-col cols="6">
            End Date
            <b-form-datepicker :disabled="disabledButton" @input="(value) => fireFiltering('endDate', value)" :max="maxDate" :min="startDate" v-model="endDate"/>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col cols="6">
            Model Number
            <b-form-input
            :disabled="disabledButton"
            @input="(value) => modelFiltering(value)"
            v-model="modelNumber"
            type="search"
            placeholder="Search by Model Number"
          />
          </b-col>
          <b-col cols="6">
            Dealer Code
            <b-form-input
            :disabled="disabledButton"
            @input="(value) => dealerFiltering(value)"
            v-model="dealerCode"
            type="search"
            placeholder="Search by Dealer Code"
          />
          </b-col>
        </b-row>
        <br>
        OR
        <br>
        <br>
        <b-row>
          <b-col cols="6">
            DO Number
            <b-form-input
            @input="(value) => sapDoFiltering(value)"
            v-model="search"
            :disabled="disabledDoButton"
            type="search"
            placeholder="Search by DO Number"
          />
          </b-col>
          <b-col cols="6"/>
        </b-row>
        <br>
        <b-row  v-if="disableDownloadButton">
          <b-col style="margin-top:20px;">
            <span style="color:red"> *The data result is more than 50.000, please narrow down the date range</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <b-button :disabled="disableDownloadButton" variant="primary" style="margin-top:20px" @click="openDownloadModal">
            Download 
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import * as XLSX from 'xlsx'
import moment from 'moment';
import axios from '@/axios';
import lodash from 'lodash';
import { userAccess, dateFormat,convertPDFtoBase64 } from "@/utils/utils";

export default {
  data() {
    return {
      fields: [
        { key: 'customerPo', label: "Customer PO", filterable: true, sortable: true},
        { key: 'rspOrderNumber', label: 'RSP Order Number', filterable: true, sortable: true },
        { key: 'sapSo', label: 'SAP SO', filterable: true, sortable: true, },
        { key: 'sapDo', label: 'SAP DO', filterable: true, sortable: true },
        { key: 'deliveryDate', label: 'DO Date', filterable: true, sortable: true },
        { key: 'deliveryStatus', label: 'Status', filterable: true, sortable: true },
        { key: 'scanStatus', label: 'Scan Status', filterable: true, sortable: true },
        { key: 'barcode', label: 'Barcode', filterable: true, sortable: true },
        { key: 'serial', label: 'Serial', filterable: true, sortable: true },
        { key: 'itemModel', label: 'Model', filterable: true, sortable: true },
      ],      
      filter: {
        'Order Number' : 'orderNumber',
        'SAP SO' : 'sapSo',
        'SAP DO' : 'sapDo',
        'Order Date' : 'orderDate',
        'Delivery Date' :'deliveryDate',
        'Priority' : 'priority',
        'Shipping Point' : 'shippingPoint',
        'Customer Name1' : 'CustomerName1',
        'Customer Name2' : 'CustomerName2',
        'Customer Name3' : 'CustomerName3',
        'Customer Name4' : 'CustomerName4',
        'Delivery Address1' : 'deliveryAddress1',
        'Delivery Address2' : 'deliveryAddress2',
        'Delivery Address3' : 'deliveryAddress3',
        'Delivery Status' : 'deliveryStatus',
        'Status' : 'status',
        'Sales Person Code' : 'salesPersonCode',
        'Salesman' : 'salesman',
        'Project Name' : 'projectName',
        'Phier' : 'phier',
        'Cust Group' : 'custgroup',
        'Item Model' : 'itemModel',
        'Description' : 'description',
        // 'Phlvl1' : 'phlvl1',
        'Phlvl1' : {field: "phlvl1", callback: value => {return ` ​${value}`}},
        'Phlvl2' : {field: "phlvl2", callback: value => {return ` ​${value}`}},
        'Serial' : 'serial',
        'Barcode' : 'barcode',
        'Scan Status' : 'scanStatus',
        'Scan Time' : 'scanTime',
        'Scan User' : 'scanUser.email',
        'Unscan Time' : 'unscanTime',
        'Unscan User' : 'scanUser.email',
        'Status' : 'warrantyStatus'
      },
      inventoryToDownload:[],
      search: '',
      soNumber: '',
      currentPage: 1,
      perPage: 10,
      totalData: 1,
      disableDownloadButton: false,
      minmin:'',
      startDate: '',
      endDate: '',
      modelNumber: '',
      dealerCode: '',
      maxDate: '',
      busyLoading: true,
      totalRows:1,
      promptDownload: false,
      disabledDoButton: false,
      noData : false,

      //download numbers
      downPage:0,
      dataDownload:0,
      inventoryToDownload:[],
      disabledButton: false,
    }
  },
  mounted() {
    
  },
  computed: { 
    progressDownload () {
        let downloaded = this.inventoryToDownload.length
        let dataToDownload = this.totalRows
        return Math.ceil((downloaded/dataToDownload)*(100))
    },
    ...mapGetters({
      inventory : 'report/inventory'
    }),
    ...mapState({
      filterOrder: (state) => {
        if (state.filter.filterContainer['report-inventory-list'] !== undefined) {
          let orderFilter = state.filter.filterContainer['report-inventory-list'];
          return orderFilter;
        }

        return {};
      },
    }),
    mappedInventory(){
      return this.inventory.map(item => {
        return {
          ...item,
          rspOrderNumber: item.orderNumber.includes('-') &&  item.orderNumber.startsWith('RSP')? item.orderNumber.substring(0, 12) : '',
          customerPo: item.orderNumber.includes('-') &&  item.orderNumber.startsWith('RSP') ? item.orderNumber.substring(11) : item.orderNumber
        }
      })
    },
   reactiveStartDate: {
      get() {
        if(this.filterOrder.startDate === undefined){
          this.filterOrder.startDate = this.startDate
        }
      
        return this.filterOrder.startDate;
      },
      set() {
        this.updateFilterInventory({
          name: 'report-inventory-list',
          data: {
            startDate: this.filterOrder.startDate
          }
        });

      }
    },
    hapus(){
      // console.log('this.endDate',this.endDate);
    },
    reactiveEndDate: {
      get() {
        if(this.filterOrder.endDate === undefined){
          this.filterOrder.endDate = this.endDate;
        }
        return this.filterOrder.endDate;
      },
      set() {
        this.updateFilterInventory({
          name: 'report-inventory-list',
          data: {
            endDate: this.filterOrder.endDate
          }
        });

      }
    },   
  },
  methods: {
    ...mapActions({
      inventoryList : 'report/getInventory',
      getFilterInventory: 'filter/getFilter',
      updateFilterInventory: 'filter/updateFilter'
    }),
    async openDownloadModal(){
      this.downPage = 0
      this.dataDownload = 0
      this.inventoryToDownload = []
      this.promptDownload=true;
      let filter = this.filterOrder; 
      let page = 0
      var dataDownload=this.dataDownload
      if (this.search ==='') {
        while (dataDownload < this.totalRows) {
          let dataProcess = []
          page++
          this.downPage = page  
           await this.inventoryList({
              ...filter,
              forDownload:true,
              model:this.modelNumber,
              dealer: this.dealerCode,
              page: page, 
              entry: 500,
            
            }).then((res) => {
            // this.warrantyToDownload=data.data;
            dataProcess=res.data;
            // split into each item 
            this.inventoryToDownload.push(...dataProcess)
            dataDownload += dataProcess.length
            this.dataDownload = dataDownload
          });  
        }
      } else{
        while (dataDownload < this.totalRows) {
          let dataProcess = []
          page++
          this.downPage = page  
           await this.inventoryList({
              forDownload:true,
              sapDo:this.search,
              page: page, 
              entry: 500,
            
            }).then((res) => {
            // this.warrantyToDownload=data.data;
            dataProcess=res.data;
            // split into each item 
            this.inventoryToDownload.push(...dataProcess)
            dataDownload += dataProcess.length
            this.dataDownload = dataDownload
          });  
        }
      }
      

    },
    onPageChange(page) {
      this.currentPage=page;

      this.inventoryList({
        startDate : this.reactiveStartDate, 
        endDate : this.reactiveEndDate,
        page: this.currentPage, 
        entry: this.perPage,
        sapDo: this.search,
        sapSo: this.soNumber
        
       
      }).then((data) => this.processMetadata(data.metadata));
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      this.totalRows = totalData;
      this.busyLoading = false;
      if (this.totalRows >= 50000) {
       this.disableDownloadButton = true
      }else{
        this.disableDownloadButton = false
      }

      if (this.totalRows === 0) {
        this.noData = true
      }else {
        this.noData = false
      }
    },
    saveFilter(key, value) { //save updated filter
      let tempFilter = this.filterOrder; 

      if (value === null) {
        tempFilter[key] = undefined;
      } else {
        tempFilter[key] = value;
      }
      
      this.updateFilterInventory({ //save to store named is "filter"
        name: 'report-inventory-list',
        data: { ...tempFilter,entry: this.perPage,page:1 }
      });
    },
    fireFiltering(key, value) {
      this.saveFilter(key, value); //filter saved date
      
      let filter = this.filterOrder; 
      filter.sapDo = this.search
      filter.sapSo = this.soNumber
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const minDate = new Date(this.endDate)
      minDate.setFullYear(minDate.getFullYear() - 1)
      this.minmin = minDate

      this.busyLoading = true;
      this.inventoryList({ ...filter, model: this.modelNumber, dealer: this.dealerCode ,entry: this.perPage,page:1}).then((data) => {
        this.processMetadata(data.metadata)
      });
    },
    sapDoFiltering: lodash.debounce(function (value){
      this.modelNumber = ''
      this.dealerCode = ''
      this.search=value;
      this.busyLoading = true
      if (this.search!== "") {
        this.disabledButton = true
        this.inventoryList({
          page: 1, 
          entry: this.perPage,
          sapDo:this.search,
          startDate : "", 
          endDate : "",
        }).then((data) => {
          this.processMetadata(data.metadata)
          this.busyLoading = false
        });  
      }else{
        this.disabledButton = false
        this.busyLoading = true
        this.inventoryList({
          page: 1, 
          entry: this.perPage,
          startDate : this.reactiveStartDate, 
          endDate : this.reactiveEndDate,
          sapDo:this.search,
        }).then((data) => {
          this.processMetadata(data.metadata)
          this.busyLoading = false
        });  
        this.busyLoading = false
      }
    },500),

    modelFiltering: lodash.debounce(function (value){
      this.modelNumber = value
      this.busyLoading = true
      if (this.modelNumber!== "") {
        this.search= ""
        this.disabledDoButton = true
        this.inventoryList({
          page: 1, 
          entry: this.perPage,
          startDate : this.reactiveStartDate, 
          endDate : this.reactiveEndDate,
          model: this.modelNumber
        }).then((data) => {
          this.processMetadata(data.metadata)
          this.busyLoading = false
        });  
      }else{
        this.busyLoading = false
        if (this.dealerCode == '' && this.modelNumber == '') {
          this.disabledDoButton = false
        }
      }
    },500),

    dealerFiltering: lodash.debounce(function (value){
      this.dealerCode = value
      this.busyLoading = true
      if (this.dealerCode!== "") {
        this.search= ""
        this.disabledDoButton = true
        this.inventoryList({
          page: 1, 
          entry: this.perPage,
          dealer : this.dealerCode,
          startDate : this.reactiveStartDate, 
          endDate : this.reactiveEndDate,
        }).then((data) => {
          this.processMetadata(data.metadata)
          this.busyLoading = false
        });  
      }else{
        this.busyLoading = false
        if (this.dealerCode == '' && this.modelNumber == '') {
          this.disabledDoButton = false
        }
      }
    },500),

    dates(date) {
      return dateFormat(date);
    },
    resetNoData() {
      this.noData = false;
    }
  },
  created() {
    // Setup Store To Save Filtering Information
    this.getFilterInventory('report-inventory-list');

    let now = new Date();
    let yearAgo = new Date(now.getFullYear()-1, now.getMonth()+1, now.getDate())
    
    let monthAgo= new Date();
    monthAgo.setDate(monthAgo.getDate()-7);

    
    this.maxDate = now;
    this.startDate = monthAgo;
    this.endDate = now

    const minDate = new Date(this.endDate)
    minDate.setFullYear(minDate.getFullYear() - 1)
    this.minmin = minDate

    document.title = 'Inventory Report | RSP'

    this.inventoryList({
      startDate : this.reactiveStartDate, 
      endDate : this.reactiveEndDate,
      page: this.currentPage, 
      entry: this.perPage,   
    }).then((data) => this.processMetadata(data.metadata));
  },
}
</script>
